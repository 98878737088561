import axios from 'axios';

const http = axios.create({
    // baseURL: 'https://ie-chat-bot-lab-multiplossender.lys.academy/api/v1', // lab
    // baseURL: 'https://f9f9-167-249-188-57.ngrok-free.app/api/v1', // teste
    baseURL: 'https://internal-api.lys.academy/api/v1', // prod
    headers: { 'Content-Type': 'application/json' }
});

const http_file = axios.create({
    // baseURL: 'https://ie-chat-bot-lab-multiplossender.lys.academy/api/v1', // lab
    // baseURL: 'https://f9f9-167-249-188-57.ngrok-free.app/api/v1', // teste
    baseURL: 'https://internal-api.lys.academy/api/v1', // prod
    headers: { 'Content-Type': 'multipart/form-data' }
});

http.interceptors.request.use(config => {
    const token = localStorage.getItem("user_session") ? localStorage.getItem("user_session"): 'd0668c56d2b7bb95c9f2d9b76d4e87f83341c9bd';

    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
        config.headers['X-CSRFToken'] = token;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

http_file.interceptors.request.use(config => {
    const token = localStorage.getItem("user_session") ? localStorage.getItem("user_session"): 'd0668c56d2b7bb95c9f2d9b76d4e87f83341c9bd';
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
        config.headers['X-CSRFToken'] = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export { http, http_file }
